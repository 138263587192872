import React from 'react';
import { mergeProps } from 'next-merge-props';

import { getServerSideIndexProps } from '../modules/page/getServerSideIndexProps';
import { getServerSideCookieProps } from '../modules/cookies/getServerSideCookieProps';

import { PageProps } from '../context/PageData';

import Page from '../components/Page';

export default function IndexPage(data: PageProps) {
  return <Page {...data} />;
}

export const getServerSideProps = mergeProps([
  getServerSideIndexProps,
  getServerSideCookieProps
]);
